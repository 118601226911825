import axios from "axios";
import { allBoatsPath, unlinkedBoats, getHeader } from "./../../../config";
const state = {
  boats: [],
  barcos_ind: [],
  barcos_art: [],
};

const mutations = {
  SET_BOATS(state, payload) {
    state.boats = payload;
  },
  UNLINKED_BOATS(state, payload) {
    state.unlinked = payload;
  },
};

const actions = {
  getBoats: async function ({ commit }, { type, options }) {
    await axios
      .get(`${allBoatsPath}/${type}`, { headers: getHeader(), params: options })
      .then((response) => {
          commit("SET_BOATS", response.data);
      });
  },
  getUnlinked({ commit }) {
    axios.get(unlinkedBoats, { headers: getHeader() }).then((response) => {
      if (response.status === 200) {
        commit("UNLINKED_BOATS", [...new Set(response.data.data)]);
      }
    });
  },
};

const getters = {
  allBoats: (state) => state.boats,
  getTodosBarcosInd: (state) => state.barcos_ind,
  getTodosBarcosArt: (state) => state.barcos_art,
  UnlinkedBoats: (state) => state.unlinked,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
