
   
import mapper from "../mapper";
export default {
  methods: {
    $can(permissionInCamelCase) {
      const normalTextPermission = mapper.camelCaseToNormalText(permissionInCamelCase);
      return this.$isSuperAdmin() || JSON.parse(
      localStorage.getItem("authUser")
    ).all_permissions.indexOf(normalTextPermission) !== -1;
    },
    $canArray(permissionInCamelCase) {
      var arrayFinal = [];

      let permissions = JSON.parse(
        localStorage.getItem("authUser")
      ).all_permissions;

      permissions.forEach(element => {
        var normalElement = mapper.camelCaseToNormalText(element);
        permissionInCamelCase.forEach(elementP => {
          var normalElementP = mapper.camelCaseToNormalText(elementP);
          if(normalElement == normalElementP){
            arrayFinal.push(normalElementP);
          }
        })
      });

      return arrayFinal.length > 0 || this.$isSuperAdmin();
    },
    $isSuperAdmin(){
      return JSON.parse(
        localStorage.getItem("authUser")
      )?.roles?.[0]?.name === 'Super Administrador';
    }
  },
};