import Vue from "vue";
import Vuex from "vuex";

// Modules
//import app from './modules/app.js';
import axios from 'axios';
import user from "./modules/user";
import fisherman from "./modules/fisherman";
import boat from "./modules/boat";
import company from "./modules/company";
import patentes from "./modules/patentes";
import technical_inspection from "./modules/technical_inspection";
import statutes_violation from "./modules/statute-violation";
import certificate_patent from "./modules/certificate_patent";
import concept_technical from "./modules/concept_technical";
import roles from "./modules/roles";
import filtros from "./modules/filtros";
import organizations from "./modules/organizations";
import { getAuthUserUrl } from "../config";
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false,
    notifications: [],
    settings: [],
    active_route: "",
    isLoggedIn: !!localStorage.getItem('authUser'),
    variablesConcepto: {},
    isRunningFetch: false
  },

  mutations: {
    LOADER(state, payload) {
      state.loader = payload;
    },
    START_LOADER(state) {
      state.loader = true;
    },
    STOP_LOADER(state) {
      state.loader = false;
    },
    NOTIFICATIONS(state, payload) {
      state.notifications = payload;
    },
    ACTIVE_ROUTE(state, payload) {
      state.active_route = payload;
    },
    SETTINGS(state, payload) {
      state.settings = payload;
    },
    CURRENT_ROUTE(state, payload) {
      state.active_route = payload;
    },
    IS_LOGGED(state, payload){
      state.isLoggedIn = payload;
    },
    PUSH_NOTIFICATION(state, payload){
      state.notifications.unshift(payload);
    },
    NOTIFICATION_READ(state, payload){
      let index = state.notifications.findIndex(x => x.id === payload);
      if(index === -1) return;
      state.notifications[index].pivot.read_at = moment();
    },
    SET_VARIABLES_CONCEPTO(state, payload){
      state.variablesConcepto = {...state.variablesConcepto, ...payload};
    },
    SET_RUNNING(state, payload){
      state.isRunningFetch = payload;
    }
  },
  getters: {
    getSaveBoatParcial: state => state.saveBoatParcial,
    getVariableConcepto: state => state.variablesConcepto
  },
  actions: {
    setLoader({ commit }, status) {
      commit("LOADER", status);
      commit("START_LOADER", status);
      commit("STOP_LOADER", status);
    },
    async validateToken({commit}) {
      try {
        const { data } = await axios.post(getAuthUserUrl); 
        commit('IS_LOGGED', Boolean(data));
      } catch (error) {
        commit('IS_LOGGED', false);
      }
    }
  },

  modules: {
    user,
    fisherman,
    boat,
    company,
    patentes,
    technical_inspection,
    statutes_violation,
    certificate_patent,
    concept_technical,
    roles,
    filtros,
    organizations
  },
  strict: process.env.NODE_ENV !== "production",
});
