import axios from "axios";
import {
  getAllConceptTechnicalUrl,
  getAllConceptTechnicalArtUrl,
  getHeader,
} from "./../../../config";

const state = {
  concept_technical: [],
  concept_technical_art: [],
};

const mutations = {
  GET_CONCEPT_TECHNICAL(state, payload) {
    state.concept_technical = payload;
  },
  GET_CONCEPT_TECHNICAL_ART(state, payload) {
    state.concept_technical_art = payload;
  },
};

const actions = {
  getConceptTechnicalInd({ commit }) {
    // action goes here
    axios
      .get(getAllConceptTechnicalUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempConceptTechnical = response.data.data;
          tempConceptTechnical.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }

            if (item.technical_inspectin_motor_boat == 1) {
              item.technical_inspectin_motor_boat_text = "SI";
            } else {
              item.technical_inspectin_motor_boat_text = "NO";
            }

            if (item.application_letter == 1) {
              item.application_letter_text = "SI";
            } else {
              item.application_letter_text = "NO";
            }

            if (item.type_patent == "IND") {
              item.type_patent_text = "INDUSTRIAL";
            } else {
              item.type_patent_text = "ARTESANAL";
            }
          });
          commit("GET_CONCEPT_TECHNICAL", tempConceptTechnical);
        }
      });
  },
  getConceptTechnicalArt({ commit }) {
    // action goes here
    axios
      .get(getAllConceptTechnicalArtUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempConceptTechnical = response.data.data;
          tempConceptTechnical.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }

            if (item.technical_inspectin_motor_boat == 1) {
              item.technical_inspectin_motor_boat_text = "SI";
            } else {
              item.technical_inspectin_motor_boat_text = "NO";
            }

            if (item.application_letter == 1) {
              item.application_letter_text = "SI";
            } else {
              item.application_letter_text = "NO";
            }

            if (item.type_patent == "IND") {
              item.type_patent_text = "INDUSTRIAL";
            } else {
              item.type_patent_text = "ARTESANAL";
            }
          });
          commit("GET_CONCEPT_TECHNICAL_ART", tempConceptTechnical);
        }
      });
  },
};

const getters = {
  allConceptTechnical: (state) => state.concept_technical,
  allConceptTechnicalArt: (state) => state.concept_technical_art,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
