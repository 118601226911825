const state = {
  nameRol: "sanciones",
  roles: [],
};

const mutations = {
  obtenerPermiso(state) {
    const pruebas = localStorage.getItem("authUser");
    state.roles = pruebas.permission;
  },
};

const actions = {
  validarPermiso({ commit }) {
    const pruebas = localStorage.getItem("authUser");
    commit("obtenerPermiso", pruebas.permission);
  },
};

const userModule = {
  state,
  mutations,
  actions,
};

export default userModule;
