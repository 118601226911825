import axios from "axios";
import {
  getAllCertificatePatentUrl,
  getAllCertificatePatentArtUrl,
  getHeader,
} from "./../../../config";

const state = {
  certificate_patent: [],
  certificate_patent_art: [],
};

const mutations = {
  GET_CERTIFICATE_PATENT(state, payload) {
    state.certificate_patent = payload;
  },
  GET_CERTIFICATE_PATENT_ART(state, payload) {
    state.certificate_patent_art = payload;
  },
};

const actions = {
  getCertificatePatentInd({ commit }) {
    // action goes here
    axios
      .get(getAllCertificatePatentUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempCertificatePatent = response.data.data;
          tempCertificatePatent.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }

            if (item.technical_inspectin_motor_boat == 1) {
              item.technical_inspectin_motor_boat_text = "SI";
            } else {
              item.technical_inspectin_motor_boat_text = "NO";
            }

            if (item.application_letter == 1) {
              item.application_letter_text = "SI";
            } else {
              item.application_letter_text = "NO";
            }

            if (item.type_patent == "IND") {
              item.type_patent_text = "INDUSTRIAL";
            } else {
              item.type_patent_text = "ARTESANAL";
            }
          });
          commit("GET_CERTIFICATE_PATENT", tempCertificatePatent);
        }
      });
  },
  getCertificatePatentArt({ commit }) {
    // action goes here
    axios
      .get(getAllCertificatePatentArtUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempCertificatePatent = response.data.data;
          tempCertificatePatent.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }

            if (item.technical_inspectin_motor_boat == 1) {
              item.technical_inspectin_motor_boat_text = "SI";
            } else {
              item.technical_inspectin_motor_boat_text = "NO";
            }

            if (item.application_letter == 1) {
              item.application_letter_text = "SI";
            } else {
              item.application_letter_text = "NO";
            }

            if (item.type_patent == "IND") {
              item.type_patent_text = "INDUSTRIAL";
            } else {
              item.type_patent_text = "ARTESANAL";
            }
          });
          commit("GET_CERTIFICATE_PATENT_ART", tempCertificatePatent);
        }
      });
  },
};

const getters = {
  allCertificatePatent: (state) => state.certificate_patent,
  allCertificatePatentArt: (state) => state.certificate_patent_art,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
