export default {
  methods: {
    $showLoading() {
     this.$store.commit("LOADER", true);
     // this.$store.commit("START_LOADER");
    },
    $hideLoading(){
      this.$store.commit("LOADER", false);
      //this.$store.commit("STOP_LOADER");
    }
  },
};
