import BaseService from "./BaseService";
import downloadFile from './../utils/download';

const api = "organizations";

export function getOrganizationsAll(params = {}) {
    return BaseService.get(`${api}`, {
        params: params
    });
}

export function showOrganizationById(id) {
    return BaseService.get(`${api}/show/${id}`);
}

export function saveOrganizations(obj) {
    return BaseService.post(`${api}/store`, obj);
}

export function updateOrganizations(obj) {
    return BaseService.put(`${api}/update`, obj);
}

export function getSupportTypes() {
    return BaseService.get(`${api}/supports/types`);
}

export function uploadSupport(formdata) {
    return BaseService.post(`${api}/supports/store`, formdata, {
        headers: { 'content-type': 'multipart/form-data' }
    });
}
export function supportList(organization_id) {
    return BaseService.get(`${api}/supports/index/${organization_id}`);
}

export async function downloadSupport(id, name) {
    const response = await BaseService.get(`${api}/supports/download/${id}`, {
        responseType: 'blob'
    });

    downloadFile(response.data, 'application/pdf', name);
}

export async function deleteSupport(id){
    return await BaseService.delete(`${api}/supports/delete/${id}`);
}

export async function viewSupport(id){
    const response = await BaseService.get(`${api}/supports/download/${id}`, {
        responseType: 'blob'
    });

    const url = URL.createObjectURL(response.data);
    window.open(url, '_blank');
}