import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import canMixin from "../utils/mixins/canMixin";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("../views/Login"),
    },
    {
        path: "/login",
        name: '["login"]',
        component: () => import("../views/Login"),
      },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("../views/Dashboard.vue"),
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        store.commit("CURRENT_ROUTE", `/dashboard`);

        next();
      },

      children: [
        {
            path: "",
            name: '["welcome"]',
            component: () =>
                import ("../views/Welcome.vue"),
            meta: { requiresAuth: true },
        },
        {
          path: "/not-autorized",
          name: "not-autorized",
          component: () => import("../views/Not-autorized.vue"),
        },
        {
          path: "/users",
          name: '["verUsuarios"]',
          component: () => import("../views/Users.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/users-activity",
          name: '["verUsuarios"]',
          component: () => import("../views/UsersActivity.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/users`);

            next();
          },
        },
        {
          path: "/investigation-by-violation-statute",
          name: '["verListaDeInvestigaciones"]',
          component: () => import("../views/Investigation.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/investigation-by-violation-statute`);
            next();
          },
        },
        {
          path: "/create-investigation-by-violation-statute-:id",
          props: (route) => ({ process: "new", ...route.params }),
          name: '["gestionarInvestigacion"]',
          component: () => import("../views/NewInvestigation.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit(
              "CURRENT_ROUTE",
              `/investigation-by-violation-statute`
            );

            next();
          },
        },
        {
          path: "/view-investigation-by-violation-statute-:id",
          props: (route) => ({ process: "view", ...route.params }),

          name: '["view-investigation-by-violation-statute"]',
          component: () => import("../views/NewInvestigation.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit(
              "CURRENT_ROUTE",
              `/investigation-by-violation-statute`
            );

            next();
          },
        },
        {
          path: "/resolv-investigation-by-violation-statute-:id",
          props: (route) => ({ process: "resolv", ...route.params }),
          name: '["resolv-investigation-by-violation-statute"]',
          component: () => import("../views/NewInvestigation.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit(
              "CURRENT_ROUTE",
              `/investigation-by-violation-statute`
            );

            next();
          },
        },
        {
          path: "/fishermen-:type/:organization?",
          props: true,
          name: '["verPermisionariosIndustriales","verPermisionariosArtesanales","verListaPescadoresDeportivos"]',
          component: () => import("../views/Fishermen.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/fishermen-${to.params.type}`);
            next();
          },
        },

        {
          path: "/boats-:type",
          name: '["verEmbarcacionesIndustriales", "verEmbarcacionesArtesanal"]',
          props: true,
          component: () => import("../views/Boats.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/boat-${to.params.type}`);
            next();
          },
        },
        {
          path: "/view-fishing-card/:id_fisherman",
          name: '["view-fishing-card"]',
          component: () => import("../views/ViewFishingCard.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/new-fisherman-:type",
          name: '["gestionarPescadorIndustrial","gestionarPescadorArtesanal","gestionarPescadorDeportivo"]',
          props: (route) => ({ type_request: "UNO", ...route.params }),
          component: () => import("../views/fisherman/requestForm.vue"),

          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/fishermen-${to.params.type}`);
            next();
          },
        },
        {
          path: "/request-fisherman-:type-:type_request-:pid-:process-:request_id",
          name: '["gestionarPescadorIndustrial","gestionarPescadorArtesanal","gestionarPescadorDeportivo"]',
          props: (route) => ({ ...route.params }),
          component: () => import("../views/fisherman/requestForm.vue"),

          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/fishermen-${to.params.type}`);
            next();
          },
        },
        {
          path: "/request-fisherman-:type",
          props: true,
          name: '["gestionarPescadorIndustrial","gestionarPescadorArtesanal","gestionarPescadorDeportivo"]',
          component: () => import("../views/fisherman/list_requests.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/fishermen-${to.params.type}`);
            next();
          },
        },
        {
          path: "/expirations-notifications",
          props: true,
          name: 'expirations-notifications',
          component: () => import("../views/notifications"),
          meta: { requiresAuth: true },
        },
        {
          path: "/settings/:type",
          props: true,
          name: '["settings"]',
          component: () => import("../views/settings"),
          meta: { requiresAuth: true },
        },
        {
          path: "/companies-:type",
          props: true,
          name: '["verPermisionariosArtesanales","verPermisionariosIndustriales"]',
          component: () => import("../views/Companies.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", to.fullPath);
            next();
          },
        },
        {
          path: "/new-patent-request-:type-:boat_num",
          props: (route) => ({
            process: "new",
            type_request: "pp",
            title: "CP",
            ...route.params,
          }),
          name: '["requestPatent"]',
          component: () => import("../views/company/requests.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/boats-${to.params.type}`);
            next();
          },
        },
        {
          path: "/company-request-:type/:process/:nit/:consecutive",
          props: true,
          name: '["gestionarProcesosArtesanales","gestionarProcesosIndustriales","aprobarProcedimientosArtesanales","aprobarProcedimientosIndustriales"]',
          component: () => import("../views/company/requests.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/companies-${to.params.type}`);
            next();
          },
        },
        {
          path: "/requests-companies-:type",
          props: true,
          name: '["verProcedimientosArtesanales","verProcedimientosIndustriales"]',
          component: () => import("../views/company/list_requests.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/companies-${to.params.type}`);
            next();
          },
        },
        {
          path: "/track-fishing",
          props: true,
          name: '["verConsultasArtesanales","verConsultasIndustriales","verConsultasDeportivas"]',
          component: () => import("../components/ComponentTrackFishing.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/track-fishing`);
            next();
          },
        },
        {
          path: "/track-fishing/:cod_tracing",
          props: true,
          name: '["verConsultasArtesanales","verConsultasIndustriales","verConsultasDeportivas"]',
          component: () => import("../components/ComponentTrackFishing.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/track-fishing`);
            next();
          },
        },
        {
          path: "/track-fishing-notification",
          props: true,
          name: "trackFishingNotification",
          component: () => import("../components/ComponentTrackFishing.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/track-fishing-notification`);
            next();
          },
        },
        {
          path: "/list-boat-:type",
          props: true,
          name: '["verConsultasArtesanales","verConsultasIndustriales","verConsultasDeportivas"]',
          component: () => import("../components/ComponentConsultasBoats.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/list-boat-${to.params.type}`);
            next();
          },
        },
        {
          path: "/list-patente-:type",
          props: true,
          name: '["verConsultasArtesanales","verConsultasIndustriales","verConsultasDeportivas"]',
          component: () => import("../components/ComponentConsultasPatentes.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/list-patente-${to.params.type}`);
            next();
          },
        },
        {
          path: "/list-fisherman-art",
          name: '["verConsultasArtesanales"]',
          props: true,
          component: () => import("../views/consultas/ListaFishermanArt.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/list-fisherman-art`);
            next();
          },
        },
        {
          path: "/list-fisherman-dep",
          name: '["verConsultasDeportivas"]',
          component: () => import("../views/consultas/ListaFishermanDep.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/list-fisherman-dep`);
            next();
          },
        },
        {
          path: "/list-fisherman-ind",
          name: '["verConsultasIndustriales"]',
          component: () => import("../views/consultas/ListaFishermanInd.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/graphic-boat",
          name: '["graphic-boat"]',
          component: () => import("../views/consultas/GraphicBoats.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/graphic-boat`);
            next();
          },
        },
        {
          path: "/graphic-fishermen",
          name: '["graphic-fishermen"]',
          component: () => import("../views/consultas/GraphicFishermen.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/organizations",
          name: '["verListadosConDetalleOrganizaciones"]',
          component: () => import("../views/organization/Create.vue"),
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/organizations`);
            next();
          },
        },
        {
          path: "/general-config",
          name: '["general.config"]',
          component: () => import("../views/general-config/Index.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/general-config`);
            next();
          },
        },
        {
          path: "/scv-pesca",
          name: '["verScvPesca"]',
          component: () => import("../views/scvPesca.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/scv-pesca`);
            next();
          },
        },
        {
          path: "/monitoring",
          name: '["verMonitoreo"]',
          component: () => import("../views/Monitoring.vue"),
          meta: { requiresAuth: true },
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/monitoring`);
            next();
          },

        },
         {
                    path: "/measurement",
                    name: "measurement",
                    props: true,
                    component: () =>
                        import ("../views/Measurement.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "/weightcheck",
                    name: "weightcheck",
                    props: true,
                    component: () =>
                        import ("../views/WeightCheck.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "/activities",
                    name: "activities",
                    props: true,
                    component: () =>
                        import ("../views/Activities.vue"),
                    meta: { requiresAuth: true },
                },
                {
                    path: "/landing",
                    name: "landing",
                    props: true,
                    component: () =>
                        import ("../views/Landing.vue"),
                    meta: { requiresAuth: true },
                },
        {
          path: "/organizations",
          name: '["verListadosConDetalleOrganizaciones"]',
          component: () => import("../views/organization/Create.vue"),
          beforeEnter: (to, from, next) => {
            store.commit("CURRENT_ROUTE", `/organizations`);
            next();
          },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  let path = to.path.replaceAll("/", "");
  const userLocal = JSON.parse(window.localStorage.getItem("authUser") || "{}");
  if (
    to.matched.some((route) => route.meta.requiresAuth) &&
    !store.state.isLoggedIn
  ) {
    next({ name: '["login"]' });
    return;
  } else if (path === "login" || path === "") {
    store.state.isLoggedIn && (await store.dispatch("validateToken"));
    if (store.state.isLoggedIn) {
      window.location.href = "/dashboard";
      return;
    } else {
      next();
      return;
    }
  }

  if (userLocal?.roles?.[0]?.name == "Super Administrador") {
    next();
  } else {
    if(path != "dashboard" && path != "not-autorized")
    {
        let band = false;
        const permissions = JSON.parse(to.name);
        permissions.forEach(function (value) {
          if (canMixin.methods.$can(value)) {
            next();
            band = true;
          }
        });

        if (!band) next({ name: "not-autorized" });
    }
  }

  next();
});

export default router;
