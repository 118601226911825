import axios from "axios";
import { getAllCompaniesUrl, getAllRequestCompanies, getHeader } from "./../../../config";

const state = {
  companies: [],
  requests: [],
  currentPage: null,
  currentFilters: { currentItemsPerPage: 10},
};

const mutations = {
  SET_COMPANIES(state, payload) {
    state.companies = payload;
  },
  SET_REQUESTS(state, payload) {
    state.requests = payload;
  },
  SET_CURRENT_PAGE(state, payload) {
    state.currentPage = payload;
  },
  SET_CURRENT_FILTERS(state, payload) {
    state.currentFilters = {...state.currentFilters, ...payload};
  }
};

const actions = {
  getCompanies: async function ({ commit }, { type, options }) {
    await axios
      .get(getAllCompaniesUrl + "/" + type, { headers: getHeader(), params: options})
      .then(({status, data}) => {
        if (status === 200) {
          commit("SET_COMPANIES", data);
        }
      });
  },
  getRequests: async function ({ commit }, { type, options }) {
    try {
      const { data } = await axios.get(getAllRequestCompanies + "/" + type, {
        headers: {
          ...getHeader(),
          'Cache-Control': 'no-cache'
        },
        params: options
      })
      commit("SET_REQUESTS", data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error trying get requests company', error);
    }

  },
};

const getters = {
  allCompanies: (state) => state.companies,
  allRequests: (state) => state.requests,
  currentPage: (state) => state.currentPage,
  currentFilters: (state) => state.currentFilters,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
