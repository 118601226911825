import { getOrganizationsAll } from "../../../services/OrganizationService";

const state = {
  organizations: [],
};

const mutations = {
  SET_ORGANIZATIONS(state, payload) {
    state.organizations = payload;
  },
};

const actions = {
  getOrganizations: async function ({ commit }) {
    try {
      const { data } = await getOrganizationsAll();
      commit("SET_ORGANIZATIONS", data);
    } catch (error) {
      this.$toasted.show("Error al obtener las organizaciones", {
        theme: "bubble",
        position: "bottom-right",
        duration: 4000,
      });
      // eslint-disable-next-line no-console
      console.error("[ERROR]: trying to get organizations", error);
    }
  },
};

const getters = {
  allOrganizations: (state) => state.organizations,
};

const organizationModule = {
  state,
  mutations,
  actions,
  getters,
};

export default organizationModule;
