import axios from "axios";
import {
  getRequestFishermen,
  getAllRequestFishermen,
  getAllFishermen,
  getFisherman,
  getHeader,
} from "./../../../config";

const state = {
  fishermens: [],
  fishermenData: [],
  reqFishermenData: [],
  fishingCard: null,
  requestsFisherman: [],
};

const mutations = {
  LIST_FISHERMEN(state, payload) {
    state.fishermens = payload;
  },
  GET_FISHERMEN_DATA(state, payload) {
    state.fishermenData = payload;
  },
  SET_REQ_FISHERMEN_DATA(state, payload) {
    state.reqFishermenData = payload;
  },
  SET_FISHING_CARD(state, payload) {
    state.fishingCard = payload;
  },
  SET_REQUESTS_LIST(state, payload) {
    state.fishermens.solicitudes = payload;
  },
  SET_REQUEST(state, payload) {
    state.fishermens.solicitudes[payload.index].estado = payload.estado;
  },
  LIST_REQUEST_FISHERMEN(state, payload) {
    state.requestsFisherman = payload;
  },
  SET_FISHERMAN_DATA(state, payload) {
    const indexRequest = state.requestsFisherman.data.findIndex((x) => x.id == payload.id);
    state.requestsFisherman.data[indexRequest] = payload;
  },
};

const actions = {
  getFishermen: async function ({ commit }, data) {
    await axios
      .get(getFisherman + "?q=" + data._id, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          commit("GET_FISHERMEN_DATA", response.data);
        }
      });
  },
  getRequestFishermen: async function ({ commit }, data) {
    await axios
      .get(getRequestFishermen + "?q=" + data._id, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          commit("SET_REQ_FISHERMEN_DATA", response.data);
        }
      });
  },
  listFishermen: async function (
    { commit },
    { type, params}
  ) {
    await axios
      .get(
        `${getAllFishermen}/${type}`,
        { headers: getHeader(), params }
      )
      .then((response) => {
        if (response.status === 200) {
          commit("LIST_FISHERMEN", response.data);
        }
      });
  },
  listRequestFishermen: async function (
    { commit },
    { type, filter={}, page, itemsPerPage, queryParams: { search } = {}, sortable }
  ) {
    let finalFilter = Object.assign({}, filter, sortable)
    await axios
      .get(
        `${getAllRequestFishermen}/${type}?page=${page}&itemsPerPage=${itemsPerPage}${
          search ? "&search=" + search : ""
        }`,
        { headers: getHeader(), params:  finalFilter }
      )
      .then((response) => {
        if (response.status === 200) {
          commit("LIST_REQUEST_FISHERMEN", response.data);
        }
      });
  },
};

const getters = {
  allFishermen: (state) => state.fishermens,
  allRequestFishermen: (state) => state.requestsFisherman,
  fishermenData: (state) => state.fishermenData,
  reqFishermenData: (state) => state.reqFishermenData,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;