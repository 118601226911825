import axios from "axios";
import { apiDomain, apiVersion, baseUrl } from "../config";

const baseService = axios.create({
  baseURL: `${apiDomain}${baseUrl}${apiVersion}`,
});
baseService.interceptors.request.use((config) => {
  const authUser = JSON.parse(window.localStorage.getItem("authUser"));
  if (authUser && authUser.access_token) {
    config.headers.Authorization = "Bearer " + authUser.access_token;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";

  return config;
});
export default baseService;