import axios from "axios";
import {
  getAllTechnicalInspectionInUrl,
  getAllTechnicalInspectionArtUrl,
  getHeader,
} from "./../../../config";

const state = {
  technical_inspection_in: [],
  technical_inspection_art: [],
};

const mutations = {
  GET_TECHNICAL_INSPECTION(state, payload) {
    state.technical_inspection_in = payload;
  },
  GET_TECHNICAL_INSPECTION_ART(state, payload) {
    state.technical_inspection_art = payload;
  },
};

const actions = {
  getTechnicalInspectionInd({ commit }) {
    // action goes here
    axios
      .get(getAllTechnicalInspectionInUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempTechnical = response.data.data;
          tempTechnical.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }
          });
          commit("GET_TECHNICAL_INSPECTION", tempTechnical);
          //commit("SET_USER_LIST", response.data.data);
          //return response.data;
        }
      });
  },
  getTechnicalInspectionArt({ commit }) {
    // action goes here
    axios
      .get(getAllTechnicalInspectionArtUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          let tempTechnical = response.data.data;
          tempTechnical.map((item) => {
            switch (item.request_type) {
              case "UNO":
                item.request_type_text = "Nuevo Permiso";
                break;
              case "DOS":
                item.request_type_text = "Prorroga";
                break;
              case "TRES":
                item.request_type_text = "Vinculación";
                break;
              case "CUATRO":
                item.request_type_text = "Desvinculación";
                break;
              case "CINCO":
                item.request_type_text = "Reemplazo motonave";
                break;
              case "SEIS":
                item.request_type_text = "Cancelación del Permiso";
                break;
              case "SIETE":
                item.request_type_text = "Patente de Pesca";
                break;
              case "OCHO":
                item.request_type_text = "Otro, Cual?";
                break;
            }
          });
          commit("GET_TECHNICAL_INSPECTION_ART", tempTechnical);

          //commit("SET_USER_LIST", response.data.data);
          //return response.data;
        }
      });
  },
};

const getters = {
  allTechnicalInspectionIn: (state) => state.technical_inspection_in,
  allTechnicalInspectionArt: (state) => state.technical_inspection_art,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
