import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuelidate from "vuelidate";

import VueHtmlToPaper from "vue-html-to-paper";
import VueHighcharts from "vue-highcharts";
import excel from "vue-excel-export";
import VueTheMask from "vue-the-mask";
import CKEditor from "@ckeditor/ckeditor5-vue";
import vueDebounce from "vue-debounce";

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.1.12/vuetify.css",
    "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
    "https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.css",
    "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css",
    "https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/4.7.95/css/materialdesignicons.css",
    //'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
};

import Toasted from "vue-toasted";
import VueGallery from "vue-gallery";
import canMixin from "./utils/mixins/canMixin";
import loaderMixin from "./utils/mixins/loaderMixin";
import moment from "moment";
import 'moment/locale/es';

Vue.component("VGallery", VueGallery);
Vue.use(Toasted);
Vue.use(vuetify, {
  theme: {
    primary: "#0579ff", // a links
    secondary: "#424242",
    accent: "#82B1FF",
    error: "#FF5252",
    info: "#2196F3",
    success: "#4CAF50",
    warning: "#FFC107",
  },
});
Vue.use(excel);
Vue.use(VueHighcharts);
Vue.use(VueHtmlToPaper, options);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(CKEditor);
Vue.use(vueDebounce, {
  listenTo: ["input", "keyup"],
});
axios.interceptors.request.use((config) => {
  const authUser = JSON.parse(window.localStorage.getItem("authUser"));
  if (authUser && authUser.access_token) {
    config.headers.Authorization = "Bearer " + authUser.access_token;
  }
  config.headers["Access-Control-Allow-Origin"] = "*";

  return config;
});

Vue.config.productionTip = false;

// Global Guards
router.beforeEach((to, from, next) => {
  //("ruta ", to);

  if (to.meta.requiresAuth) {
    const authUser = JSON.parse(window.localStorage.getItem("authUser"));

    if (authUser && authUser.access_token) {
      next();
    } else {
      next({ name: "login" });
    }
  }

  next();
});

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase();
})
Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})
Vue.filter('pascal', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.replace(/(_\w)/g, k => ' '+ k[1].toUpperCase())
})
Vue.mixin(canMixin)
Vue.mixin(loaderMixin)
Vue.filter('formatAgo', function (value) {
  moment.locale('es');
  return moment(value).fromNow();
});
Vue.filter('formatDefault', function (value) {
  moment.locale('es');
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
});
Vue.filter('formatDate', function (value) {
  moment.locale('es');
  return moment(value).format('DD/MM/YYYY');
});
new Vue({
  router,
  store,
  vuetify,
  el: "#app",
  render: (h) => h(App),
});
