import { getBoatIndUrl, getBoatArtUrl, getHeader } from "./../../../config.js";
import axios from "axios";
const state = {
  boatInd: "",
  filterInd: {
    bandera: "",
    landing_zone: "", //  zona de pesca autorizada
    port_of_landing: "", // puerto desembarco
    authorized_arts: "", // pesqueria autorizada
    fishing_arts: "", // arte de pesca
  },
  list_port_of_landing: [],
  list_authorized_arts: [],
  list_landing_zone: [],
  list_fishing_arts: [],
  list_of_boats: [],
};

const mutations = {
  setFilterInd(state, data) {
    state.filterInd[data["filter"]] = data.value;
  },
  setAddBoatInd(state, payload) {
    state.boatInd = JSON.parse(JSON.stringify(payload));
  },
  setPortOflanding(state, payload) {
    state.list_port_of_landing = payload;
  },
  setAuthorizedArts(state, payload) {
    state.list_authorized_arts = payload;
  },
  setLandingZone(state, payload) {
    state.list_landing_zone = payload;
  },
  setfishing_arts(state, payload) {
    state.list_fishing_arts = payload;
  },

  get_boat_art(state, payload) {
    state.list_of_boats = payload;
  },
};

const actions = {
  getBoatIn({ commit }) {
    axios.get(getBoatIndUrl, { headers: getHeader() }).then((response) => {
      if (response.status === 200) {
        let setPort_of_landing = [];
        let setAuthorized_arts = [];
        let setLanding_zone = [];
        response.data.boadsInsdustrials.forEach((element) => {
          setPort_of_landing.push(element.port_of_landing);
          setAuthorized_arts.push(element.authorized_arts);
          setLanding_zone.push(element.landing_zone);
          setPort_of_landing.push("UNO");
        });
        commit("setAddBoatInd", response.data.boadsInsdustrials);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setPortOflanding", setPort_of_landing);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setAuthorizedArts", setAuthorized_arts);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setLandingZone", setLanding_zone);

        //
      }
    });
  },
  getBoatArt({ commit }) {
    axios.get(getBoatArtUrl, { headers: getHeader() }).then((response) => {
      if (response.status === 200) {
        let setPort_of_landing = [];
        let setAuthorized_arts = [];
        let setLanding_zone = [];
        let setFishing_arts = [];
        let listBoatArt = response.data.boadsArtesanals;
        response.data.boadsArtesanals.forEach((element) => {
          setPort_of_landing.push(element.port_of_landing);
          setAuthorized_arts.push(element.authorized_arts);
          setLanding_zone.push(element.landing_zone);
          setFishing_arts.push(element.fishing_arts);
        });

        commit("get_boat_art", listBoatArt);

        commit("setAddBoatInd", response.data.boadsArtesanals);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setPortOflanding", setPort_of_landing);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setAuthorizedArts", setAuthorized_arts);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setLandingZone", setLanding_zone);
        /**
         * se agrega array para los selected de los filtros
         */
        commit("setfishing_arts", setFishing_arts);

        //
      }
    });
  },

  validarPermiso({ commit }) {
    const pruebas = localStorage.getItem("authUser");
    commit("obtenerPermiso", pruebas.permission);
  },
};
const getters = {
  filterInd(state) {
    let listBoatInd = JSON.parse(JSON.stringify(state.boatInd));

    if (state.filterInd.port_of_landing) {
      listBoatInd = listBoatInd.filter(
        (boatInd) => boatInd.port_of_landing === state.filterInd.port_of_landing
      );
    }
    if (state.filterInd.authorized_arts) {
      listBoatInd = listBoatInd.filter(
        (boatInd) => boatInd.authorized_arts === state.filterInd.authorized_arts
      );
    }
    if (state.filterInd.landing_zone) {
      listBoatInd = listBoatInd.filter(
        (boatInd) => boatInd.landing_zone === state.filterInd.landing_zone
      );
    }
    if (state.filterInd.fishing_arts) {
      listBoatInd = listBoatInd.filter(
        (boatInd) => boatInd.fishing_arts === state.filterInd.fishing_arts
      );
    }
    return listBoatInd;
  },
  filterindPort_of_landing() {
    let setlist_port_of_landing = JSON.parse(
      JSON.stringify(state.list_port_of_landing)
    );
    return setlist_port_of_landing;
  },
  filterindAuthorized_arts() {
    let setlist_authorized_arts = JSON.parse(
      JSON.stringify(state.list_authorized_arts)
    );
    return setlist_authorized_arts;
  },
  filterindLanding_zone() {
    let setlist_landing_zone = JSON.parse(
      JSON.stringify(state.list_landing_zone)
    );
    return setlist_landing_zone;
  },
  filterindFishing_arts() {
    let setlist_fishing_arts = JSON.parse(
      JSON.stringify(state.list_fishing_arts)
    );
    return setlist_fishing_arts;
  },

  allBoatsArt: (state) => state.list_of_boats,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
