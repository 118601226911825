import axios from "axios";
import {
  getAllStatutesViolationInUrl,
  getStatutesViolationInUrl,
  getHeader,
} from "./../../../config";

const state = {
  statute: {},
  statutes_violations: [],
};

const mutations = {
  GET_STATUTE(state, payload) {
    state.statute = payload;
  },
  GET_STATUTES_VIOLATIONS(state, payload) {
    state.statutes_violations = payload;
  },
};

const actions = {
  getStatuteViolation({ commit }) {
    // action goes here
    axios
      .get(getAllStatutesViolationInUrl, { headers: getHeader() })
      .then((response) => {
        commit("GET_STATUTES_VIOLATIONS", response.data);
        //commit("SET_USER_LIST", response.data.data);
        //return response.data;
      });
  },
  getStatute: async function ({ commit }, data) {
    // action goes here
    await axios
      .get(getStatutesViolationInUrl + "/" + data, { headers: getHeader() })
      .then((response) => {
        commit("GET_STATUTE", response.data);

        //commit("SET_USER_LIST", response.data.data);
        //return response.data;
      });
  },
};

const getters = {
  allStatutes: (state) => state.statutes_violations,
  Statute: (state) => state.statute,
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
