<template>
  <div class="text-center">
    <v-dialog v-model="loader" persistent width="300" class="mx-auto">
      <v-card color="indigo darken-3" dark>
        <v-card-text class="text-center">
          <v-progress-circular
            :size="80"
            color="white"
            indeterminate
            class="mt-3"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    loader() {
      return this.$store.state.loader;
    },
  },
};
</script>
