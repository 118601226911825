import axios from "axios";
import { allPatentesPath, getHeader } from "./../../../config";
const state = {
  patentes: []
};

const mutations = {
  SET_PATENTES(state, payload) {
    state.patentes = payload;
  }
};

const actions = {
  getPatentes: async function ({ commit }, { type, options }) {
    await axios
      .get(`${allPatentesPath}/${type}`, { headers: getHeader(), params: options })
      .then((response) => {
          commit("SET_PATENTES", response.data);
      });
  },
};

const getters = {
  allPatentes: (state) => state.patentes
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
