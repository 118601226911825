import axios from "axios";
import permissionService from "../../../services/permissionService";
import {
  getAllUsersUrl,
  getAllUsersActivityUrl,
  getHeader,
} from "./../../../config";
import canMixin from "../../../utils/mixins/canMixin";
const ROL_ADMIN = 'Super Administrador';
const state = {
  users: [],
  users_activity: [],
  systemPermissions: [],
  systemRoles: [],
  systemPermissionByRole: [],
  systemPermissionByUser: [],
};

const mutations = {
  GET_USERS(state, payload) {
    state.users = payload;
  },
  GET_USERS_ACTIVITY(state, payload) {
    state.users_activity = payload;
  },
  SET_PERMISSIONS(state, payload) {
    state.systemPermissions = payload;
  },
  SET_ROLES(state, payload) {
    state.systemRoles = payload;
  },
  SET_PERMISSIONS_BY_ROLE(state, payload) {
    state.systemPermissionByRole = payload;
  },
  SET_PERMISSIONS_BY_USER(state, payload) {
    state.systemPermissionByUser = payload;
  },
  
};

const actions = {
  getUsers({ commit }) {
    axios.get(getAllUsersUrl, { headers: getHeader() }).then((response) => {
      if (response.status === 200) {
        let data = response.data.data;
        if (!canMixin.methods.$isSuperAdmin()){
          data = data.filter(x => x.roles[0]?.name !== ROL_ADMIN);
        }
        commit("GET_USERS", data);
      }
    });
  },
  getUsersActivity({ commit }) {
    axios
      .get(getAllUsersActivityUrl, { headers: getHeader() })
      .then((response) => {
        if (response.status === 200) {
          commit("GET_USERS_ACTIVITY", response.data);
        }
      });
  },
  async getPermissions({ commit }, { params } = {}) {
    let { data } =  await permissionService.getPermissions(params);
    if (!canMixin.methods.$isSuperAdmin()){
      data = data.filter(x => !["Ver permisos", "Editar permisos"].includes(x.name))
    }
    commit("SET_PERMISSIONS", data);
  },
  async getRoles({ commit }, { params } = {}) {
    let { data } =  await permissionService.getRoles(params);
    if (!canMixin.methods.$isSuperAdmin()){
      data = data.filter(x => x.name !== ROL_ADMIN);
    }
    commit("SET_ROLES", data);
  },
  async getPermissionsByRole({ commit }, role) {
    const { data } =  await permissionService.getPermissionsByRole(role.id);
    commit("SET_PERMISSIONS_BY_ROLE", data);
  },
  async getPermissionsByUser({ commit }, user) {
    const { data } =  await permissionService.getPermissionsByUser(user);
    commit("SET_PERMISSIONS_BY_USER", data);
  },
};

const getters = {
  allUsers: (state) => state.users,
  allUsersActivity: (state) => state.users_activity,
  allPermissions: (state) => state.systemPermissions,
  allPermissionsByRole: (state) => state.systemPermissionByRole,
  allPermissionsByUser: (state) => state.systemPermissionByUser,
  allRoles: (state) => state.systemRoles,
  
};

const userModule = {
  state,
  mutations,
  actions,
  getters,
};

export default userModule;
