
import BaseService from "../services/BaseService";

const resource = 'permission';

export default {
    getRoles(params = {}) {
        let url = `${resource}/roles?`;
        return BaseService.get(url, {params});
    },
    getPermissions(params =  {}) {
       return BaseService.get(`${resource}/permissions`, {params});
    },
    getPermissionsByRole(roleId) {
        return BaseService.get(`${resource}/roles/permissions/${roleId}`);
    },
    getPermissionsByUser(user) {
        return BaseService.get(`${resource}/user/permissions/${user.id}`);
    },
    createPermission(data){
        return BaseService.post(`${resource}/permissions/store`, data);
    },
    syncPermissionByRole(role, data){
        return BaseService.put(`${resource}/roles/assing-permissions/${role.id}`, data);
    },
    syncPermissionByUser(userId, data){
        return BaseService.put(`${resource}/user/assing-permissions/${userId}`, data);
    },
    storeRole(data){
        return BaseService.post(`${resource}/roles/store`, data);
    },
    updateRole(role){
        return BaseService.put(`${resource}/roles/update/${role.id}`, role);
    },
    removeRole(role){
        return BaseService.delete(`${resource}/roles/remove/${role.id}`);
    },
    roleGivePermissionTo(data){
        return BaseService.post(`${resource}/roles/give-permission-to`, data);
    },
    roleRevokePermissionTo(data){
        return BaseService.post(`${resource}/roles/revoke-permission-to`, data);
    }
}
